<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <!-- 申请编号 -->
            <div class="search_center">
              <span>申请编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入申请编号"
                clearable
              >
              </el-input>
            </div>
            <!-- 部门 -->
            <div class="search_center">
              <span>部门：</span>
              <el-select
                v-model="parameter.deptId"
                @change="dataUpdate"
                clearable
                filterable
                placeholder="请选择部门"
                class="ipt_width"
              >
                <el-option
                  v-for="item in deptList"
                  :key="item.id"
                  :label="item.deptName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 申请人 -->
            <div class="search_center">
              <span>申请人：</span>
              <el-input
                v-model="parameter.createName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入申请人"
                clearable
              >
              </el-input>
            </div>
            <!-- 所属单位 -->
            <div class="search_center">
              <span>所属单位：</span>
              <el-select
                v-model="parameter.companyType"
                @change="dataUpdate"
                clearable
                filterable
                placeholder="请选择所属单位"
                class="ipt_width"
              >
                <el-option
                  v-for="item in companyTypeList"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.dictVal"
                >
                </el-option>
              </el-select>
            </div>

            <span>进度状态：</span>
            <Dictionary
              v-model="parameter.parentStatus"
              class="ipt_width"
              placeholder="请选择进度状态"
            />

            <el-date-picker
              class="date_width"
              v-model="createdDate"
              :pickerOptions="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="申请日期（始）"
              end-placeholder="申请日期（末）"
            >
            </el-date-picker>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">申请流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.status" class="fullScreenMainHeader">
            <el-tab-pane name="1">
              <span slot="label">未提交 </span>
            </el-tab-pane>
            <el-tab-pane name="2">
              <span slot="label">审批中 </span>
            </el-tab-pane>
            <el-tab-pane name="3">
              <span slot="label">不通过 </span>
            </el-tab-pane>
            <el-tab-pane name="4">
              <span slot="label">已通过 </span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column prop="expenseNumber" label="申请编号" align="center"></el-table-column>
            <el-table-column
              prop="createName"
              label="申请人"
              align="center"
              width="120"
            ></el-table-column>
            <el-table-column prop="deptName" label="部门" align="center"></el-table-column>
            <el-table-column
              prop="companyTypeName"
              label="费用所属单位"
              align="center"
            ></el-table-column>
            <el-table-column prop="applicantMoney" label="申请金额(元)" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost }}
              </template>
            </el-table-column>
            <el-table-column prop="projectBudget" label="申请时间" align="center">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="statusName" label="流程进度">
              <template slot-scope="scope">
                {{ scope.row.statusName }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleDetails(scope.row)"
                  v-if="scope.row.status != 0"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleEdit(scope.row)"
                  v-if="edit_status(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleDelete(scope.row)"
                  v-if="edit_status(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="公共费用报销预申请流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/公共费用报销预申请.png" alt="公共费用报销预申请流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit v-if="editShow" :isShow.sync="editShow" :getTitle="sonTitle" :options="form"></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../edit/Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        status: '1', //1未审核，2审核中，3已审批
        expenseNumber: '', //申请编号
        createName: '', //申请人
        deptId: '', //部门编号
        startDate: '', //查询开始时间
        endDate: '', //查询结束时间
        parentStatus: '',
      },
      pageSize: 0,
      total: 0, // 表格 -- 数据 总数量
      tableData: [], // 表格数据
      projectList: [], // 项目数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      fundsId: null, // 经费预支 id
      userList: [], //用户信息列表
      companyTypeList: [], //公司所属单位列表
      deptList: [], // 部门 列表
      createdDate: [], //查询内容申请时间
      drawer: false, //流程图显示隐藏
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  async created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    // 获取用户信息
    await this.getCompanyList()
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    await this.getTableData()
  },
  watch: {
    'parameter.applyType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getTableData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    getDeptList() {
      this.$api.sysDept
        .listDept()
        .then(res => {
          this.deptList = res.data
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    /** 加载 "经费预支申请" 数据 **/
    getTableData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.loading = true
      // todo 缺少接口
      this.$api.publicSpendingExpense
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          this.total = res.data.total
          res.data.records.forEach(item => {
            // 根据companyType查公司所属单位
            const findCompanyType = this.companyTypeList.find(
              companyTypeItem => companyTypeItem.dictVal == item.companyType
            )
            if (findCompanyType) {
              item.companyTypeName = findCompanyType.dictName
            }
          })
          this.tableData = res.data.records
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.createName = ''
      this.parameter.companyType = ''
      this.parameter.deptId = ''
      this.parameter.parentStatus = ''
      this.parameter.startDate = ''
      this.parameter.endDate = ''
      this.parameter.expenseNumber = ''
      this.createdDate = []
      this.getTableData()
    },
    /** 点击 "新增" 按钮 **/
    handleAdd() {
      this.sonTitle = '公共费用报销申请详情'
      this.form = {}
      //* detailType用来标识详情的类型，0代表填报详情，1代表审核详情
      this.form.detailType = 0
      this.editShow = true
    },

    /**
     * 点击状态
     * @param val     当前值
     */
    handleStatus(val) {
      this.parameter.status = val
      this.getTableData()
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.sonTitle = '公共费用报销申请详情'
      this.form = item
      this.form.detailType = 0 //  标识申请列表
      this.form.list_s = 2 //点击详情
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    handlerPage(page) {
      this.parameter.pageNow = page
      this.getTableData()
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.form = {}
      this.getTableData()
      this.editShow = false
    },

    edit_status(item) {
      if (
        item.status == '0' || //暂存
        item.status == '5' //审核不通过
      ) {
        return true
      } else {
        return false
      }
    },

    handleEdit(item) {
      this.sonTitle = '公共费用报销申请详情'
      this.form = item
      this.form.detailType = 0
      this.showEditDialog = true
    },

    handleDelete() {
      this.$confirm('请确认是否删除当前申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // *这里发起删除请求
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>

<style>
.search_center {
  display: flex;
  align-items: center;
}
.row1 {
  display: flex;
}
</style>
